export const Tooltip = {
  textAlign: 'left',
  padding: '0px 8px',
};

export const TooltipDescription = {
  ...Tooltip, // inherit styles from Tooltip
  fontSize: '0.8em',
  fontWeight: 'normal',
};

export const DashboardContainer = {
  maxWidth: '1400px',
  margin: '20px auto',
  alignItems: 'flex-start',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
};

export const DashboardCard = {
  flexBasis: 'calc(25% - 5vw)', // Use viewport units for a more responsive approach
  flexGrow: 1,                  // Allows the item to grow if necessary
  flexShrink: 1,                // Allows the item to shrink if necessary
  border: '1px solid #6e6e6e',
  padding: '0px',
  opacity: 1,
  transition: 'opacity 450ms ease-in-out, transform 450ms ease-in-out',
};

// Assuming you're using a system like styled-components or @emotion, you could include media queries like:
const mediaQueries = {
  '@media(max-width: 1200px)': {
    flexBasis: 'calc(50% - 1vw)',
  },
  '@media(max-width: 800px)': {
    flexBasis: 'calc(100% - 1vw)',
  },
}

// Then merge the base style with the media queries:
const responsiveDashboardCard = {
  ...DashboardCard,
  ...mediaQueries
};

export default responsiveDashboardCard;


export const DashboardCardHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center', // To ensure vertical alignment
  // border: '1px solid #6e6e6e',
  padding: '9px',  // Adjust as needed
};

