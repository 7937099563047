const AdditionalResources = () => {
  return (
    <div>
      <br></br>
      {/* <article>
        <header>Embrace Linux</header>
        <p><a href="https://larbs.xyz/" target="_blank" rel="noreferrer">LARBS</a> Luke's Auto-Rice Bootstrapping Scripts - A set of scripts for automatic installation and configuration of a minimal, efficient Linux environment.</p>
        <p><a href="https://nakamotoinstitute.org/static/docs/taoup.pdf" target="_blank" rel="noreferrer">The Art of Unix Programming</a> book by Eric S. Raymond - A comprehensive guide to the philosophy and culture of the Unix operating system.</p>
        <p><a href="https://archive.org/details/Free_Software_Free_Society" target="_blank" rel="noreferrer">Free Software, Free Society</a> book by Richard Stallman - A collection of essays on the philosophy of free software, the development and use of the GNU operating system, and the political and social issues of software freedom.</p>
      </article>
      <article>
        <br></br>
        <header>Take Control of Your Data</header>
        <p><a href="https://nostr-resources.com/" target="_blank" rel="noreferrer">Nostr: Securely Transmit Notes and Other Stuff</a> - A decentralized network for public events, allowing anyone to build their own applications or services that can interact with the rest of the network.</p>
        <p><a href="https://github.com/dani-garcia/vaultwarden" target="_blank" rel="noreferrer">VaultWarden Password Manager</a> (<a href="https://www.youtube.com/watch?v=7IsjoazT11s" target="_blank" rel="noreferrer">Video Tutorial</a>) - An unofficial Bitwarden compatible server written in Rust, capable of running on small devices like Raspberry Pi for self-hosted password management.</p>
      </article>
      <br></br> */}
      <header>Essential Reads</header>
      <p><a href="https://nakamotoinstitute.org/static/docs/cypherpunk-manifesto.txt" target="_blank" rel="noreferrer">A Cypherpunk's Manifesto</a> by Eric Hughes - A seminal work advocating for the use of cryptography to protect individual privacy in the digital age.</p>
      <p><a href="https://nakamotoinstitute.org/crypto-anarchist-manifesto/" target="_blank" rel="noreferrer">The Crypto Anarchist Manifesto</a> by Timothy C. May - A key document predicting the rise of cryptocurrency and potential societal changes from cryptographic technologies.</p>
    </div>
  )
}

export default AdditionalResources
