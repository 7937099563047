const GenesisBlock = () => {
  return (
    // <div className="genesis-block">
    // </div>
    <div className="intro">
      <p>I stand for privacy and freedom in the digital realm.</p>
      <p>Over the years, I've laid a solid foundation, honing my skills and exploring the vast potential of the digital world.</p>
      <p>Now, I'm embarking on a new journey, diving into the technical realms of Bitcoin.</p>
      <p>Join me in this journey as we navigate this fascinating landscape, unravel its complexities, and strive to make it accessible for anyone.</p>
    </div>
  );
}

export default GenesisBlock;