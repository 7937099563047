import React, { useState } from "react";
import { useTooltip } from './TooltipContext';

const Tooltip = ({ id, description, showToggleSymbols = true, clickPosition = { x: 0, y: 0 } }) => {
  const { openTooltip, setOpenTooltip } = useTooltip();
  const show = openTooltip === id;
  const [isHovered, setIsHovered] = useState(false);

  const tooltipContainerStyle = {
    position: 'relative',
  };

  const defaultStyle = {
    cursor: isHovered ? 'pointer' : 'default',
    fontWeight: isHovered ? 'bold' : 'normal',
    color: 'var(--color-font)',
  };

  const descriptionStyle = {
    display: show ? 'block' : 'none',
    position: 'fixed', // use fixed to position based on the viewport
    top: `${clickPosition.y}px`,
    left: `${clickPosition.x}px`,
    fontWeight: 'normal',
    width: '300px',
    backgroundColor: 'var(--color-secondary)',
    color: 'var(--color-font-light)',
    padding: '2px',
    borderRadius: '3px',
  };

  return (
    <div style={tooltipContainerStyle}>
      {showToggleSymbols && (
        <span
          style={defaultStyle}
          onClick={() => {
            if (show) {
              setOpenTooltip(null);
            } else {
              setOpenTooltip(id);
            }
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {show ? 'x' : '?'}
        </span>
      )}
      <div style={descriptionStyle}>{description}</div>
    </div>
  );
};

export default Tooltip;
