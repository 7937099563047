import React from 'react';
import useBlockChairData from '../../hooks/useBlockChairData';
import useBlockCypherData from '../../hooks/useBlockCypherData';
import DataBox from '../DataBox';
import Tooltip from '../Tooltip';
import { DashboardCard, DashboardCardHeader } from '../../styles';
import { formatSizeInGB } from './utils';

const MempoolData = ({ formatNumber, renderData }) => {
  const { blockChairData, blockChairLoading, blockChairError } = useBlockChairData();
  const { blockCypherData, blockCypherLoading, blockCypherError } = useBlockCypherData();

  const errors = [
    { loading: blockChairLoading, error: blockChairError },
    { loading: blockCypherLoading, error: blockCypherError },
  ];

  for (const { loading, error } of errors) {
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
  }

  return (
    <div style={DashboardCard}>
      <strong style={DashboardCardHeader}>Mempool <Tooltip id="tooltip4" description="Details about the Bitcoin mempool, showcasing the current number of unconfirmed transactions, mempool size, time since the last block was mined, as well as fee recommendations for timely transaction confirmations." /></strong>
      <DataBox data={`Mempool Tx: ${renderData(blockChairData?.mempool_transactions, formatNumber)}`} />
      <DataBox data={`Mempool Size: ${renderData(blockChairData?.mempool_size, formatSizeInGB)}`} />
      <DataBox data={`Time Since Last Block: ${renderData(blockCypherData?.time, time => Math.floor((new Date() - new Date(time)) / (60 * 1000)))} mins`} />
      <DataBox data={`Min. Fee Rate: ${renderData(blockCypherData?.low_fee_per_kb, fee => (fee / 1000).toFixed(3))} sats/vB`} />
      <DataBox data={`Suggested Fee: ${renderData(blockChairData?.suggested_transaction_fee_per_byte_sat, fee => `${fee} sats/vB`)}`} />
    </div>
  );
};

export default MempoolData;
