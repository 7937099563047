import React from 'react';
import useBlockCypherData from '../../hooks/useBlockCypherData';
import useBlockChairData from '../../hooks/useBlockChairData';
import useMempoolSpaceData from '../../hooks/useMempoolSpaceData';
import DataBox from '../DataBox';
import Tooltip from '../Tooltip';
import { DashboardCard, DashboardCardHeader } from '../../styles';
import { formatSizeInGB, formatTime } from './utils';

const BlockchainData = () => {
  const { blockCypherData, blockCypherLoading, blockCypherError } = useBlockCypherData();
  const { blockChairData, blockChairLoading, blockChairError } = useBlockChairData();
  const { mempoolSpaceData, mempoolLoading, mempoolError } = useMempoolSpaceData();

  const errors = [
    { loading: blockCypherLoading, error: blockCypherError },
    { loading: blockChairLoading, error: blockChairError },
    { loading: mempoolLoading, error: mempoolError },
  ];

  for (const { loading, error } of errors) {
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
  }

  const formattedTime = formatTime(mempoolSpaceData?.timeavg);
  const btcPrice = blockChairData?.market_price_usd;
  const largestTransactionBtc = blockChairData?.largest_transaction_24h_value_usd / btcPrice;

  return (
    <div style={DashboardCard}>
      <strong style={DashboardCardHeader}>Blockchain <Tooltip id="tooltip2" description="An overview of the current Bitcoin blockchain metrics, including block height, chain size, average block time, and the largest transaction in the last 24 hours." /></strong>
      <DataBox data={`Block Height: ${blockCypherData?.height}`} />
      <DataBox data={`Chain Size: ${formatSizeInGB(blockChairData?.blockchain_size)} GB`} />
      <DataBox data={`Remaining Blocks to Diff. Adj.: ${mempoolSpaceData?.remainingblocks}`} />
      <DataBox data={`Avg. Block Time: ${formattedTime}`} />
      <a
        href={`https://mempool.space/tx/${blockChairData?.largest_transaction_24h_hash}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <DataBox data={`Largest Tx (24h): ${largestTransactionBtc.toFixed(0)} BTC`} />
      </a>

    </div>
  );
};

export default BlockchainData;
