export const renderData = (data, formatter) => data ? formatter(data) : 'N/A';


export const formatPrice = price => {
  const numPrice = parseFloat(price);
  if (!isNaN(numPrice)) {
    return `$${numPrice.toFixed(2).toLocaleString('en-US')}`;
  }
  return 'N/A';
};


export const formatNumber = num => {
  const number = parseFloat(num);
  if (!isNaN(number)) {
    return number.toLocaleString('en-US', { maximumFractionDigits: 3 });
  }
  return 'N/A';
};


export const formatLargeNumber = (num) => {
  const absNum = Math.abs(num);
  if (absNum >= 1e12) {
    return `$${(num / 1e12).toFixed(2)} T`;
  } else if (absNum >= 1e9) {
    return `$${(num / 1e9).toFixed(2)} B`;
  } else if (absNum >= 1e6) {
    return `$${(num / 1e6).toFixed(2)} M`;
  } else if (absNum >= 1e3) {
    return `$${(num / 1e3).toFixed(2)} K`;
  } else {
    return `$${num.toLocaleString('en-US')}`;
  }
};


const BYTES_IN_KB = 1000;
const KB_IN_MB = 1000;
const MB_IN_GB = 1000;
const BYTES_IN_GB = BYTES_IN_KB * KB_IN_MB * MB_IN_GB;

export const formatSizeInGB = size => {
  const sizeInGB = size / BYTES_IN_GB; // Convert bytes to GB
  return sizeInGB.toFixed(2); // Round to three decimal places and return
};


export const formatTime = (time) => {
  const timeavg = time;  // This is your value
  const minutes = Math.floor(timeavg / 60000);
  const seconds = ((timeavg % 60000) / 1000).toFixed(0);
  const readableTime = minutes + "m " + (seconds < 10 ? '0' : '') + seconds + "s";
  return readableTime;
}


export const formatDate = (dateString) => {
  try {
    const targetDate = new Date(dateString);
    const monthNames = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = targetDate.getUTCDate();
    const month = monthNames[targetDate.getUTCMonth()];
    const year = targetDate.getUTCFullYear();

    return `${day} ${month} ${year}`;
  } catch (error) {
    console.error("Error formatting retarget date:", error);
    return 'Error';
  }
};

// ... other utility functions