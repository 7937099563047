import React, { useState, useEffect, useRef } from 'react';
import Tooltip from './Tooltip';
import { useTooltip } from './TooltipContext';
import { DashboardCard } from '../styles';

const wrapNonNumeric = (str) => {
  return str.split(/(\d+)/).map((part, index) => {
    if (isNaN(part)) {
      return <span key={index} style={prefixStyle}>{part}</span>;
    }
    return part;
  });
};

const DataBox = ({ data, tooltipDescription, tooltipId }) => {
  const [opacity, setOpacity] = useState(1);
  const [title, value] = data.split(':');
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const { openTooltip, setOpenTooltip } = useTooltip();

  useEffect(() => {
    setOpacity(0);
    setTimeout(() => setOpacity(1), 300);
  }, [value]);

  const titleRef = useRef(null);

  const wrappedValue = wrapNonNumeric(value);

  return (
    <div style={{ ...DashboardCard, ...dataBoxStyle }}>
      <span
        ref={titleRef} // <-- Attach the ref here
        style={{ ...titleStyle, cursor: tooltipDescription ? 'pointer' : 'default' }}
        onClick={() => {
          if (tooltipDescription) {
            if (openTooltip === tooltipId) {
              setOpenTooltip(null);
            } else {
              setOpenTooltip(tooltipId);
              // Get the position of the middle of the span
              const rect = titleRef.current.getBoundingClientRect();
              setClickPosition({ x: rect.left + rect.width / 2, y: rect.top + rect.height / 2 });
            }
          }
        }}
      >
        {title}:
      </span>
      <span style={{ ...valueStyle, opacity }}>
        {wrappedValue}
        {openTooltip === tooltipId && <Tooltip id={tooltipId} description={tooltipDescription} showToggleSymbols={false} clickPosition={clickPosition} />}
      </span>
    </div>
  );
};

const dataBoxStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '101%',
  margin: '-1px',
  padding: '1px',
};

const titleStyle = {
  textAlign: 'left',
  margin: '0px 5px',
  fontSize: '0.8em',
  fontWeight: 'normal',
  paddingTop: '3px',
};

const valueStyle = {
  textAlign: 'right',
  margin: '0px 7px',
  transition: 'opacity 450ms ease-in-out',
};

const prefixStyle = {
  fontSize: '0.8em',
};

export default DataBox;
