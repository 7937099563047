import React from 'react';
import useCoinGeckoData from '../../hooks/useCoinGeckoData';
import useBlockCypherData from '../../hooks/useBlockCypherData';
import useBlockChairData from '../../hooks/useBlockChairData';
import useMempoolSpaceData from '../../hooks/useMempoolSpaceData';
import DataBox from '../DataBox';
import Tooltip from '../Tooltip';
import { DashboardCard, DashboardCardHeader } from '../../styles';
import { formatDate } from './utils';

const SupplyData = ({ formatNumber, renderData }) => {
  const { coinData, coinLoading, coinError } = useCoinGeckoData();
  const { blockCypherData, blockCypherLoading, blockCypherError } = useBlockCypherData();
  const { blockChairData, blockChairLoading, blockChairError } = useBlockChairData();
  const { mempoolSpaceData, mempoolLoading, mempoolError } = useMempoolSpaceData();

  const errors = [
    { loading: coinLoading, error: coinError },
    { loading: blockCypherLoading, error: blockCypherError },
    { loading: blockChairLoading, error: blockChairError },
    { loading: mempoolLoading, error: mempoolError },
  ];

  for (const { loading, error } of errors) {
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
  }

  const calculateBlockSubsidy = (blockHeight) => {
    let subsidy = 50; // Initial subsidy

    // Determine how many halvings have occurred
    const halvings = Math.floor(blockHeight / 210000);

    // Halve the subsidy for each halving
    for (let i = 0; i < halvings; i++) {
      subsidy /= 2;
    }

    return subsidy;
  }

  const blockHeight = blockCypherData?.height;
  const blockSubsidy = calculateBlockSubsidy(blockHeight);


  const calculatePercentageIssued = (circulating, total) => {
    const circ = parseFloat(circulating);
    const tot = parseFloat(total);
    if (!isNaN(circ) && !isNaN(tot) && tot !== 0) {
      return ((circ / tot) * 100).toFixed(2) + '%';
    }
    return 'N/A';
  };


  const inflationInBTC = blockChairData?.inflation_24h / 100000000; // Convert satoshis to BTC


  let estimatedHalvingDate = 'N/A';
  if (blockCypherData?.height && mempoolSpaceData?.remainingblocks && mempoolSpaceData?.timeavg) {
    try {
      const halvingInterval = 210000;
      const nextHalvingBlock = Math.ceil(blockCypherData.height / halvingInterval) * halvingInterval;
      const blocksUntilHalving = nextHalvingBlock - blockCypherData.height;
      const timeForCurrentEpochMillis = mempoolSpaceData.remainingblocks * mempoolSpaceData.timeavg;
      const blocksAfterNextRetarget = blocksUntilHalving - mempoolSpaceData.remainingblocks;
      const timeForBlocksAfterRetargetMillis = blocksAfterNextRetarget * 10 * 60 * 1000; // 10 minutes in milliseconds
      const estimatedTimeUntilHalvingMillis = timeForCurrentEpochMillis + timeForBlocksAfterRetargetMillis;
      const currentDate = new Date();
      currentDate.setUTCHours(0, 0, 0, 0);
      const halvingDate = new Date(currentDate.getTime() + estimatedTimeUntilHalvingMillis);
      estimatedHalvingDate = formatDate(halvingDate);
    } catch (error) {
      console.error("Error calculating halving estimate:", error);
      estimatedHalvingDate = 'Error';
    }
  }

  return (
    <div style={DashboardCard}>
      {/* <strong style={DashboardCardHeader}>Supply <Tooltip description="Bitcoin money supply information" /></strong> */}
      <strong style={DashboardCardHeader}>Supply <Tooltip id="tooltip1" description="The total amount of coins currently available and in circulation. This includes data on how much has been issued, inflation rates, block rewards, and estimated dates for halving events." /></strong>
      {/* <DataBox data={`Money Supply: ${renderData(coinData?.circulating_supply, formatNumber)} ฿`} /> */}
      <DataBox
        data={`Money Supply: ${renderData(coinData?.circulating_supply, formatNumber)} ฿`}
        tooltipDescription="Detailed information about Money Supply."
        tooltipId="moneySupplyTooltip"
      />
      <DataBox data={`Percentage Issued: ${calculatePercentageIssued(coinData?.circulating_supply, coinData?.total_supply)}`} />
      <DataBox data={`Inflation (24h): ${inflationInBTC.toFixed(2)} ฿`} />
      <DataBox data={`Block Subsidy: ${renderData(blockSubsidy, formatNumber)} ฿`} />
      <DataBox data={`Halving Est.: ${estimatedHalvingDate}`} />
    </div>
  );
};

export default SupplyData;