const Footer = () => {
  // const footerStyle = {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   alignItems: 'flex-end'
  // };

  const footerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  };


  return (
    <footer style={footerStyle}>
      {/* <div className="bottom-right">&copy; 2023, RANTIL</div> */}
      <div>npub1fatn6jaxtg4ldumsyycvtzrse0a6xe7auxtnwf0sl6r6453zml8s3rnala</div>
      {/* <Weather /> */}
    </footer>
  );
};

export default Footer;
