import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const BitcoinBlogList = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await axios.get('https://rantil.xyz/api/programming-bitcoin');
      const sortedPosts = response.data.flatMap(post => post).sort((a, b) => a.id - b.id);
      setPosts(sortedPosts);
    };

    fetchPosts();
  }, []);

  return (
    <div>
      <header>My Journey</header>
      <p>As I am currently reading <a href="https://github.com/jimmysong/programmingbitcoin">"Programming Bitcoin"</a> by Jimmy Song. I've been taking notes to deepen my understanding of the concepts discussed. I'm sharing these notes as simplified, digestible posts complete with code snippets, allowing you to learn along with me. Whether you're already a Bitcoin enthusiast or just starting out, these resources aim to help you navigate Bitcoin's technical landscape as I unravel it.</p>
      {posts.map(post => (
        <div key={post.id} style={{ marginTop: '20px' }}>
          <Link to={`/programming-bitcoin/${post.id}`}>{post.title}</Link>
          <span> - {post.description}</span>
        </div>
      ))}
    </div>
  );
}

export default BitcoinBlogList;
