import React from 'react';
import bitcoinLogo from '../images/bitcoin.png';

const StudyBitcoin = () => {
  return (
    <div>
      <header>Study <img src={bitcoinLogo} alt="Bitcoin" style={{ width: '2em', verticalAlign: 'middle' }} /></header>
      <p><a href="https://nakamotoinstitute.org/bitcoin/" target="_blank" rel="noreferrer">Bitcoin: A Peer-to-Peer Electronic Cash System</a> - The original Bitcoin whitepaper by Satoshi Nakamoto, introducing the concept of a decentralized digital currency.</p>
      <p><a href="https://www.swanbitcoin.com/inventing-bitcoin/" target="_blank" rel="noreferrer">Inventing Bitcoin</a> - A book by Yan Pritzker, providing an excellent introduction to how the Bitcoin system works.</p>
      <p><a href="https://github.com/jimmysong/programmingbitcoin" target="_blank" rel="noreferrer">Programming Bitcoin</a> - A comprehensive guide by Jimmy Song, offering insights into Bitcoin programming with practical examples and exercises.</p>
      <p><a href="https://github.com/kallerosenbaum/grokkingbitcoin" target="_blank" rel="noreferrer">Grokking Bitcoin</a> - An open-source book by Kalle Rosenbaum, explaining Bitcoin with visual aids and analogies to make it accessible to anyone. Watch the related <a href="https://www.youtube.com/watch?app=desktop&v=6tHnYyaw0qw" target="_blank" rel="noreferrer">video</a> on YouTube.</p>
      <p><a href="https://bitcoindevphilosophy.com/" target="_blank" rel="noreferrer">Bitcoin Development Philosophy</a> - A guide for Bitcoin developers aiming to deepen their understanding of Bitcoin’s design trade-offs and philosophy. Authored by Kalle and Linnéa Rosenbaum.</p>
      <p><a href="https://github.com/bitcoinbook/bitcoinbook" target="_blank" rel="noreferrer">Mastering Bitcoin</a> - A detailed guide by Andreas M. Antonopoulos, covering Bitcoin from the basics to advanced topics, suitable for developers and tech enthusiasts.</p>
      <p><a href="https://www.lopp.net/bitcoin-information/technical-resources.html" target="_blank" rel="noreferrer">Technical Resources</a> on lopp.net - A collection of technical resources for developers who want to understand Bitcoin's inner workings.</p>
      {/* <p><a href="https://www.youtube.com/watch?v=Ec_7XmQIH_I" target="_blank" rel="noreferrer">Video introduction to Bitcoin privacy - from Unchained youtube channel</a></p> */}
      <p><a href="https://bitcoiner.guide/privacy/" target="_blank" rel="noreferrer">Your Guide to Bitcoin Privacy</a> - A beginner's guide that provides steps to ensure privacy when interacting with the Bitcoin network.</p>
      <p><a href="https://werunbtc.com/" target="_blank" rel="noreferrer">We Run Bitcoin</a> - A platform by Matt Odell, offering a comprehensive list of resources for learning about Bitcoin, its philosophy, and its technical aspects.</p>
    </div>
  )
}
// add in sections for developers, beginners, and philosophers, articles, books, videos, podcasts, etc.
//https://www.coindesk.com/markets/2017/03/11/nobody-understands-bitcoin-and-thats-ok/
export default StudyBitcoin;
