import React, { useState } from 'react';
import qrCode from '../images/donate.png';

const getInvoice = async () => {
  const address = 'rantil@getalby.com'
  const data = address.split('@')
  const username = data[0]
  const domain = data[1]

  const res = await fetch(`https://${domain}/.well-known/lnurlp/${username}/`)

  if (res.ok) {
    const json = await res.json()
    const callback = json.callback
    const invoiceRes = await fetch(`${callback}?amount=21000`)
    const invoiceJson = await invoiceRes.json()
    const invoice = invoiceJson.pr
    return invoice
  }
}

const Donate = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleButtonClick = async () => {
    setLoading(true);
    setError(null);
    try {
      const invoice = await getInvoice();
      if (window.webln !== undefined) {
        await window.webln.enable();
        const preimage = await window.webln.sendPayment(invoice);
        if (!preimage) {
          throw new Error('Payment failed');
        }
        // handle payment success
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <header>Donate</header>
      <p>
        Consider this a challenge: show your appreciation with a small donation over the lightning network. Just 21 sats, which is 0.00000021 BTC, is currently worth about 0.01 USD.
      </p>
      <p>
        To utilize the donation button, you'll need to have a lightning extension installed on your browser. If you're unsure where to begin, I recommend <a href="https://getalby.com/" style={{ color: '#007BFF', textDecoration: 'none' }}>getAlby</a>.
      </p>
      <button onClick={handleButtonClick} disabled={loading} style={{
        backgroundColor: '#007BFF',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s'
      }}
      onMouseOver={e => e.currentTarget.style.backgroundColor = '#0056b3'}
      onMouseOut={e => e.currentTarget.style.backgroundColor = '#007BFF'}>
        {loading ? 'Processing...' : 'Donate 21 sats'}
      </button>
      {error && <p style={{ color: 'red', marginTop: '20px', fontWeight: 'bold' }}>{error}</p>}
      <p style={{ marginTop: '20px' }}> Or donate a free amount over lightning, </p>
      <p>scan or press the QR code:</p>
      <a href="https://getalby.com/p/rantil" target="_blank" rel="noreferrer">
        <img src={qrCode} alt="QR-Code" style={{ width: '10em', verticalAlign: 'middle', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} />
      </a>
    </div>
  )
}

export default Donate;
