import React, { useEffect, useState } from 'react';
// import './App.css';

function Arrow() {
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setBlink(false);
    }, 3000); // blink for 4 seconds

    return () => clearTimeout(timer); // cleanup timer on unmount
  }, []);

  return blink ? <div className="arrow" /> : null;
}

export default Arrow;
