import React from 'react';
import useBlockChairData from '../../hooks/useBlockChairData';
import useBitNodesData from '../../hooks/useBitNodesData';
import useMempoolSpaceData from '../../hooks/useMempoolSpaceData';
import DataBox from '../DataBox';
import Tooltip from '../Tooltip';
import { DashboardCard, DashboardCardHeader } from '../../styles';

const NetworkSecurityData = ({ formatNumber }) => {
  const { blockChairData, blockChairLoading, blockChairError } = useBlockChairData();
  const { bitNodesData, bitNodesLoading, bitNodesError } = useBitNodesData();
  const { mempoolSpaceData, mempoolLoading, mempoolError } = useMempoolSpaceData();


  const errors = [
    { loading: blockChairLoading, error: blockChairError },
    { loading: bitNodesLoading, error: bitNodesError },
    { loading: mempoolLoading, error: mempoolError },
  ];

  for (const { loading, error } of errors) {
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
  }

  const formatHashRate = (hashRate) => {
    const hashRateInEH = hashRate / 1e18;
    return `${hashRateInEH.toFixed(1)} EH/s`;
  };

  function formatDifficultyChangePercentage(difficultyChange) {
    if (difficultyChange > 0) {
      return `+${difficultyChange.toFixed(2)}%`;
    } else if (difficultyChange < 0) {
      return `${difficultyChange.toFixed(2)}%`;
    } else {
      return '0%';
    }
  }

  const formatDifficulty = (difficulty) => {
    const difficultyInT = difficulty / 1e12;
    return `${difficultyInT.toFixed(1)} T`;
  };

  return (
    <div style={DashboardCard}>
      <strong style={DashboardCardHeader}>Network Security <Tooltip id="tooltip3" description="Insights into the Bitcoin network's security metrics, including the hash rate over the last 24 hours, current mining difficulty, estimated difficulty adjustments, and the number of reachable nodes." /></strong>
      <DataBox data={`Hash Rate (24h): ${formatHashRate(blockChairData?.hashrate_24h)}`} />
      <DataBox data={`Difficulty: ${formatDifficulty(blockChairData?.difficulty)}`} />
      <DataBox data={`Est. Difficulty Adj.: ${formatDifficultyChangePercentage(mempoolSpaceData?.difficultychange)}`} />
      <DataBox data={`Reachable Nodes: ${formatNumber(bitNodesData?.total_nodes)}`} />
    </div>
  );
};

export default NetworkSecurityData;
