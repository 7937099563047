import React, { createContext, useContext, useState } from "react";

const TooltipContext = createContext();

export const useTooltip = () => {
  return useContext(TooltipContext);
};

export const TooltipProvider = ({ children }) => {
  const [openTooltip, setOpenTooltip] = useState(null);

  return (
    <TooltipContext.Provider value={{ openTooltip, setOpenTooltip }}>
      {children}
    </TooltipContext.Provider>
  );
};
