import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { marked } from 'marked';
import hljs from 'highlight.js';
import 'marked-mangle';
import 'marked-gfm-heading-id';
import '../highlightTheme.css';

marked.setOptions({
  highlight: function(code, language) {
    const validLanguage = hljs.getLanguage(language) ? language : 'plaintext';
    return `<pre><code class="hljs ${validLanguage}">${hljs.highlight(code, { language: validLanguage }).value}</code></pre>`;
  },
  langPrefix: '',
  mangle: false,
  headerIds: false
});

const BitcoinBlog = () => {
  const [post, setPost] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`https://rantil.xyz/api/programming-bitcoin/${id}`)
      .then(response => {
        setPost(response.data);
      })
      .catch(error => {
        console.error("Error fetching blog posts: ", error);
      });
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  const markedContent = marked(post.content || '');

  const handleClose = () => {
    navigate('/');
  };

  return (
    <div style={{ position: 'relative', padding: '1em', margin: '1em' }}>
      <h2>{post.title}</h2>
      <div className="content" dangerouslySetInnerHTML={{__html: markedContent}} />
      <button
        onClick={handleClose}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: 'red',
          color: 'white',
          fontSize: '0.5em',
          padding: '0.5em',
          borderRadius: '10%',
          width: '30px',
          height: '30px'
        }}
        title="Close this view"
      >
        X
      </button>
      <button
        onClick={handleClose}
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          backgroundColor: 'red',
          color: 'white',
          fontSize: '0.5em',
          padding: '0.5em',
          borderRadius: '10%',
          width: '30px',
          height: '30px'
        }}
        title="Close this view"
      >
        X
      </button>
    </div>
  );
};

export default BitcoinBlog;
