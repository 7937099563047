import { useState, useEffect } from 'react';

const useGenericFetch = (url, interval = 480000) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      try {
        const response = await fetch(url, { signal });

        if (!response.ok) throw new Error(`Server responded with status: ${response.status}`);

        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("Received non-JSON response from server");
        }

        const result = await response.json();
        if (!result) {
          throw new Error('Empty response from server');
        }

        setData(result);
      } catch (err) {
        if (err.name !== 'AbortError') {  // Only handle errors that aren't aborts
          console.error('Error:', err);
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    }

    fetchData();

    const intervalId = setInterval(fetchData, interval);
    return () => {
      clearInterval(intervalId);
      abortController.abort();
    };
  }, [url, interval]);

  return { data, loading, error };
};

export default useGenericFetch;