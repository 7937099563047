import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Intro from './components/Intro';
// import Contact from './components/Contact';
import BitcoinBlog from './components/BitcoinBlog';
import BitcoinBlogList from './components/BitcoinBlogList';
import AdditionalResources from './components/AdditionalResources';
import Footer from './components/Footer';
import GenesisBlock from './components/GenesisBlock';
import Arrow from './components/Arrow';
import StudyBitcoin from './components/StudyBitcoin';
import Donate from './components/Donate';
import BitcoinData from './components/Dashboard/BitcoinData';
import { TooltipProvider } from './components/TooltipContext';  // Adjust the path as needed

function App() {
  return (
    <Router>
      <div className="App">
        <nav>
          {/* Add navigation links here */}
        </nav>
        <GenesisBlock />
        {/* <Intro /> */}
        <br></br>
        <Arrow />
        <TooltipProvider>
          <BitcoinData />
        </TooltipProvider>
        <br></br>
        <Routes>
          <Route path="/" element={<BitcoinBlogList />} />
          <Route path="/programming-bitcoin/:id" element={<BitcoinBlog />} />
        </Routes>
        <br></br>
        {/* <Contact />
        <br></br> */}
        <StudyBitcoin />
        <AdditionalResources />
        <br></br>
        <Donate />
        <br></br>
        <Footer />
      </div>
    </Router>
  );
}

export default App;