import React from 'react';
// import MarketData from './MarketData';
import SupplyData from './SupplyData';
import BlockchainData from './BlockchainData';
import NetworkSecurityData from './NetworkSecurityData';
import MempoolData from './MempoolData';
// import { TooltipDescription } from '../../styles';
import { renderData, formatNumber } from './utils';

const BitcoinData = () => {
  // ... shared states, hooks, and utility functions

  const footerStyle = {
    flex: '1 0 100%',
    textAlign: 'right',
    padding: '10px 0', // optional padding for better spacing
  };

  const textStyle = {
    fontWeight: 300, // or 'lighter'
    fontSize: '0.8em', // assuming you wanted to make the font slightly smaller
    margin: 0, // remove default paragraph margin
  };

  return (
    <div style={{ fontFamily: 'Calibri, sans-serif', maxWidth: '60vw', margin: '0px auto', display: 'flex', flexWrap: 'wrap' }}>
      {/* <MarketData formatPrice={formatPrice} formatLargeNumber={formatLargeNumber} renderData={renderData} /> */}
      <SupplyData formatNumber={formatNumber} renderData={renderData} />
      <BlockchainData />
      <NetworkSecurityData formatNumber={formatNumber} />
      <MempoolData formatNumber={formatNumber} renderData={renderData} />
      <footer style={footerStyle}>
        <p style={textStyle}>
          API calls provided by blockcypher, coingecko, mempool.space, blockchair & bitnodes.
        </p>
      </footer>
    </div >
  );
};

export default BitcoinData;